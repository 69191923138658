.label {
  align-items: center;
  background-color: var(--color-sem-surface-primary-default);
  color: var(--color-sem-text-neutral-inverted);
  display: inline-flex;
  flex-direction: row;
  gap: var(--space-sem-gap-2xsmall);
  height: var(--size-ref-7);
  padding: 0 var(--space-sem-padding-xsmall);

  svg {
    height: var(--space-ref-5);
    width: var(--space-ref-5);
  }
}

.text {
  color: var(--color-sem-text-on-primary-default);
  font-size: var(--typography-caption-md-700-font-size);
  font-weight: var(--typography-caption-md-700-font-weight);
}

.rounded {
  align-items: center;
  border-radius: var(--border-radius-ref-6);
  box-sizing: border-box;
  display: flex;
  height: var(--size-ref-9);
  justify-content: center;
  padding: var(--space-sem-padding-2xsmall);
  width: var(--size-ref-9);

  svg {
    height: var(--space-ref-7);
    width: var(--space-ref-7);
  }
}

.secondary {
  background-color: var(--color-sem-surface-neutral-inverted);
}
