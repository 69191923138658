.read-more-container {
  font-family: Poppins, 'Open Sans', sans-serif;
  padding-bottom: var(--space-sem-padding-medium);
  padding-top: var(--space-sem-padding-medium);

  :focus-visible {
    outline: var(--border-width-sem-medium) solid var(--color-sem-focus-default);
    outline-offset: var(--space-sem-padding-2xsmall);
  }
}

.article-link {
  color: var(--color-sem-link-primary-default);
  font-size: var(--text-typeset-font-size-m);
  font-weight: var(--text-typeset-font-weights-normal-600);
  margin-left: var(--space-ref-4);
  text-decoration: none;
}

.article-link-no-title {
  color: var(--color-sem-link-primary-default);
  font-size: var(--text-typeset-font-size-l);
  font-weight: var(--text-typeset-font-weights-normal-700);
  text-decoration: none;
}

.read-more-heading {
  font-size: var(--text-typeset-font-size-m);
  font-weight: var(--text-typeset-font-weights-normal-700);
}

.article-link:hover {
  text-decoration: underline;
}

.article-link-wrapper {
  line-height: 24px;
  margin-bottom: var(--space-ref-4);

  :focus-visible {
    outline: var(--border-width-sem-medium) solid var(--color-sem-focus-default);
    outline-offset: var(--space-sem-padding-2xsmall);
  }
}
