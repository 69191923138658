.highlight-color {
  color: var(--color-com-typography-heading-emp);
  font-style: italic;
}

.highlight-h1 {
  font-size: var(--typography-heading-emp-1-font-size);
  font-weight: var(--typography-heading-emp-1-font-weight);
  letter-spacing: var(--typography-heading-emp-1-letter-spacing);
  line-height: var(--typography-heading-emp-1-line-height);
}

.highlight-h2 {
  font-size: var(--typography-heading-emp-2-font-size);
  font-weight: var(--typography-heading-emp-2-font-weight);
  letter-spacing: var(--typography-heading-emp-2-letter-spacing);
  line-height: var(--typography-heading-emp-2-line-height);
}

.highlight-h3 {
  font-size: var(--typography-heading-emp-3-font-size);
  font-weight: var(--typography-heading-emp-3-font-weight);
  letter-spacing: var(--typography-heading-emp-3-letter-spacing);
  line-height: var(--typography-heading-emp-3-line-height);
}

.highlight-h4 {
  font-size: var(--typography-heading-emp-4-font-size);
  font-weight: var(--typography-heading-emp-4-font-weight);
  letter-spacing: var(--typography-heading-emp-4-letter-spacing);
  line-height: var(--typography-heading-emp-4-line-height);
}

.highlight-h5 {
  font-size: var(--typography-heading-emp-5-font-size);
  font-weight: var(--typography-heading-emp-5-font-weight);
  letter-spacing: var(--typography-heading-emp-5-letter-spacing);
  line-height: var(--typography-heading-emp-5-line-height);
}

.highlight-h6 {
  font-size: var(--typography-heading-emp-6-font-size);
  font-weight: var(--typography-heading-emp-6-font-weight);
  letter-spacing: var(--typography-heading-emp-6-letter-spacing);
  line-height: var(--typography-heading-emp-6-line-height);
}
