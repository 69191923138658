@import '../../../../theme/breakpoints';

.image-embed {
  margin-bottom: var(--space-ref-5);

  figure {
    background-color: var(--color-sem-border-primary-disabled);
    overflow: hidden;
    text-align: center;

    img {
      height: 100%;
      max-width: 100%;
      width: auto;
    }
  }
}

.image-lead {
  margin-top: var(--space-ref-7);
}

.fig-caption {
  color: var(--color-sem-text-neutral-bold);
  padding-top: var(--space-sem-padding-xsmall);
}

.camera-style {
  margin-right: var(--space-ref-2);
  position: relative;
  top: 2.5px;
}

.caption-title {
  color: var(--color-sem-text-neutral-default);
  font-size: var(--typography-caption-lg-400-font-size);
  font-weight: var(--typography-caption-sm-600-font-weight);
  margin-right: 5px;
  text-decoration: var(--typography-caption-sm-600-text-decoration);
}

.caption-credit {
  align-self: center;
  color: var(--color-sem-icon-neutral-default);
  font-size: var(--typography-caption-lg-400-font-size);
  font-weight: var(--typography-caption-md-400-font-weight);
  text-decoration: var(--typography-caption-md-400-text-decoration);
}
