.button-container {
  display: inline-block;
  position: relative;
}

.button {
  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0;

  &.button-has-fixed-dimensions > *:first-child {
    height: var(--size-sem-cta-height-medium);
    width: var(--size-sem-cta-height-medium);
  }

  &:focus-visible {
    outline: var(--border-width-ref-3) solid var(--color-sem-focus-default);
    outline-offset: var(--space-sem-padding-2xsmall);
  }
}
