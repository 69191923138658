.heading {
  color: var(--color-sem-text-neutral-default);
  font-size: var(--typography-heading-6-font-size);
  font-weight: var(--typography-heading-6-font-weight);
  letter-spacing: var(--typography-heading-6-letter-spacing);
  padding-bottom: var(--space-sem-margin-medium);
  text-transform: uppercase;
}

.recommended-wrapper {
  display: flex;
  flex-direction: column;

  article,
  .skeletton-item {
    border-bottom: var(--border-width-ref-2) solid var(--color-sem-border-neutral-subtle);
    margin-bottom: var(--space-sem-margin-medium);
    padding-bottom: var(--space-sem-margin-medium);
  }

  .skeletton-item {
    display: grid;
    gap: var(--space-ref-3);
    grid-template-columns: 1fr 1fr;

    :first-child {
      order: 2;
    }
  }
}
