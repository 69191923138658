.notification {
  align-items: center;
  background-color: var(--color-sem-surface-neutral-default);
  border: var(--border-width-ref-1) solid var(--color-sem-border-neutral-default);
  border-radius: var(--border-radius-ref-2);
  display: flex;
  gap: var(--space-ref-3);
  height: var(--size-ref-12);
  left: 50%;
  margin-top: var(--size-ref-3);
  min-width: max-content;
  padding: var(--space-ref-4) var(--space-ref-5);
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  width: auto;
  z-index: 9999;

  label {
    color: var(--color-sem-text-neutral-bold);
    font-size: var(--text-typeset-font-size-s);
    font-weight: var(--text-typeset-font-weights-normal-700);
    padding-right: 13px;
  }
}
