.badge-link {
  align-items: center;
  background: var(--color-sem-surface-primary-default);
  color: var(--color-sem-text-on-primary-default);
  display: flex;
  float: left;
  font-size: var(--text-typeset-font-size-xs);
  font-style: normal;
  font-weight: var(--text-typeset-font-weights-normal-700);
  line-height: inherit;
  margin-right: 10px;
  overflow-wrap: break-word;
  padding: var(--size-ref-2) 6px;
  text-decoration: var(--text-typeset-text-decoration-none);
  text-transform: uppercase;

  :hover {
    color: var(--color-sem-text-on-primary-default);
    text-decoration: none;
  }

  :visited {
    color: var(--color-sem-text-on-primary-default);
  }

  :focus-visible {
    outline: var(--border-width-ref-3) solid var(--acolor-sem-focus-default);
    outline-offset: var(--space-sem-padding-2xsmall);
  }
}

.affiliate-badge {
  background-color: var(--color-sem-surface-affiliate-default);
  color: var(--color-sem-text-affiliate-default);
}
