@mixin shimmer {
  animation: shimmer 1.5s infinite linear;
  background: linear-gradient(90deg, rgba(#f0f0f0, 0) 0%, rgba(#f0f0f0, 0.8) 50%, rgba(#f0f0f0, 0) 100%);
  background-size: 200% 100%;
}

@keyframes shimmer {
  from {
    background-position: 200% 0;
  }

  to {
    background-position: -200% 0;
  }
}

.timeline-skeleton {
  $base-color: var(--color-sem-surface-neutral-subdue);
  $highlight-color: var(--color-sem-surface-neutral-subtle);
  $spacing: 2rem;
  $line-width: 4px;
  $dot-size: 12px;

  &-container {
    margin: 0 auto;
    max-width: 64rem;
    padding: $spacing;
    position: relative;
    width: 100%;
  }

  &-header {
    margin-bottom: $spacing * 2;

    &-title {
      background-color: $base-color;
      border-radius: 0.375rem;
      height: 2.5rem;
      width: 60%;
      @include shimmer;
    }

    &-line {
      background-color: $base-color;
      height: 4px;
      margin-top: 1rem;
      width: 5rem;
      @include shimmer;
    }
  }

  &-line {
    background-color: $base-color;
    bottom: 0;
    left: $spacing;
    position: absolute;
    top: 6rem;
    width: $line-width;
  }

  &-item {
    margin-bottom: $spacing * 2;
    padding-left: $spacing * 2;
    position: relative;

    &::before {
      background-color: $base-color;
      border-radius: 50%;
      content: '';
      height: $dot-size;
      left: calc($spacing + ($line-width / 2) - ($dot-size / 2));
      position: absolute;
      top: 0.75rem;
      width: $dot-size;
      @include shimmer;
    }

    &-title {
      background-color: $base-color;
      border-radius: 0.375rem;
      height: 1.875rem;
      margin-bottom: $spacing;
      width: 40%;
      @include shimmer;
    }

    &-content {
      background-color: $highlight-color;
      border-radius: 0.5rem;
      box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
      padding: $spacing;

      &-image {
        background-color: $base-color;
        border-radius: 0.375rem;
        height: 16rem;
        margin-bottom: $spacing;
        @include shimmer;
      }

      &-text {
        background-color: $base-color;
        border-radius: 0.25rem;
        height: 1rem;
        margin-bottom: 0.75rem;
        @include shimmer;

        &:nth-child(2) {
          width: 90%;
        }

        &:nth-child(3) {
          width: 85%;
        }

        &:last-child {
          margin-bottom: 0;
          width: 40%;
        }
      }
    }
  }
}
