.read-next-container {
  padding-bottom: var(--space-sem-padding-medium);
  padding-top: var(--space-sem-padding-medium);

  :focus-visible {
    outline: var(--border-width-sem-medium) solid var(--color-sem-focus-default);
    outline-offset: var(--space-sem-padding-2xsmall);
  }
}

.read-next-heading {
  margin: 0 0 var(--space-ref-4);
}

.article-link {
  color: var(--color-sem-link-primary-default);
  font-size: var(--text-typeset-font-size-m);
  font-weight: var(--text-typeset-font-weights-normal-600);
  margin-left: var(--space-ref-4);
  text-decoration: none;
}

.article-link:hover {
  text-decoration: underline;
}

.article-link-wrapper {
  display: flex;
  line-height: 24px;
  margin-bottom: var(--space-ref-4);

  :focus-visible {
    outline: var(--border-width-sem-medium) solid var(--color-sem-focus-default);
    outline-offset: var(--space-sem-padding-2xsmall);
  }
}

.svg-container {
  height: var(--size-sem-icon-medium);
  width: var(--size-sem-icon-medium);
}
