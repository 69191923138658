@import '../../../../theme/breakpoints';

.container {
  background-color: var(--color-sem-surface-primary-subtle);
  border-bottom: 1px solid var(--color-sem-surface-primary-default);
  border-top: 1px solid var(--color-sem-surface-primary-default);
  padding: var(--space-ref-4);
}

.title {
  color: var(--color-sem-text-neutral-default);
  font-size: var(--text-typeset-font-size-s);
  font-weight: var(--typography-body-sm-700-font-weight);
  letter-spacing: var(--text-typeset-letter-spacing-normal);
  line-height: var(--typography-body-sm-700-line-height);

  @media (max-width: 375px) {
    font-size: calc(var(--text-typeset-font-size-s) * 0.9);
    line-height: calc(var(--typography-body-sm-700-line-height) * 0.9);
  }
}

.disclaimer {
  color: var(--color-sem-text-neutral-default);
  font-size: var(--text-typeset-font-size-2xs);
  font-weight: var(--typography-body-sm-400-font-weight);
  line-height: normal;

  a {
    color: var(--color-sem-text-neutral-default);
  }

  @media (max-width: 500px) {
    margin: 2px 0;
  }
}

.button-content {
  align-items: center;
  display: flex;

  .email-icon {
    display: none;
  }

  .subscribe-text {
    display: block;
  }

  @media (max-width: 360px) {
    .email-icon {
      display: block;
    }

    .subscribe-text {
      display: none;
    }
  }
}

.logo {
  align-self: center;
  display: flex;
  margin-right: 5px;

  @media (max-width: 728px) {
    align-self: flex-start;
  }
}

.header-container {
  align-items: center;
  display: flex;
  margin-bottom: 14px;

  @media (max-width: 500px) {
    margin-bottom: 7px;
  }
}

.input-container {
  align-items: center;
  display: flex;
  gap: 12px;
  justify-content: space-between;

  div {
    display: flex;
  }

  .newsletter-email-input {
    flex-grow: 1;
  }

  button {
    white-space: nowrap;
  }
}

.bottom-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 728px) {
    justify-content: flex-start;

    button {
      margin: 5px 0;
    }
  }

  button {
    white-space: nowrap;
  }
}

.cta-text {
  color: #4d4d4f;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-right: 15px;
  margin-top: 10px;
}

.privacy-container {
  margin-left: auto;

  @media (max-width: 728px) {
    margin-left: 0;
    width: 100%;
  }
}
