@import '../../../../theme/breakpoints';

.byline-container {
  strong {
    color: var(--color-sem-text-neutral-bold);

    /* typography/body/md/700 */
    font-size: var(--text-typeset-font-size-m);
    font-style: normal;
    font-weight: var(--text-typeset-font-weights-normal-700);
    line-height: 140%;
  }

  a > strong {
    color: var(--color-sem-link-primary-default);
    text-decoration: underline;
  }

  @media #{$large} {
    align-items: flex-start;
    flex-direction: row;
  }
}

.byline-content-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-sem-gap-small);
  padding: var(--space-sem-padding-small) 0 var(--space-sem-padding-medium);
}

.affiliate {
  background-color: var(--color-sem-surface-affiliate-subtle);
}

.author-badge-date-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--space-sem-gap-2xsmall);
  width: 100%;
}

.affiliate-wrapper {
  padding: 0 var(--space-sem-padding-small);
  @media #{$medium} {
    padding: 0 var(--space-sem-padding-medium);
  }
}

.author-and-badge-wrapper {
  a:focus-visible {
    outline: var(--border-width-sem-medium) solid var(--color-sem-focus-default);
    outline-offset: 0;
  }
}

.dates-container {
  align-items: flex-start;
  align-self: stretch;
  color: var(--color-sem-text-neutral-subdue);
  display: flex;
  flex-wrap: wrap;

  /* typography/caption/md/400 */
  font-size: var(--text-typeset-font-size-xs);
  font-style: normal;
  font-weight: var(--text-typeset-font-weights-normal-400);
  line-height: normal;
}

.published-date {
  border-right: var(--border-width-ref-2) solid var(--color-sem-border-neutral-subtle);
  margin-right: var(--space-sem-padding-xsmall);
  padding-right: var(--space-sem-padding-xsmall);
}
