.affiliate-disclaimer {
  align-items: center;
  display: flex;
  gap: var(--space-sem-gap-3xsmall);

  .info-icon {
    color: var(--color-ref-primary-600);
  }

  .affiliate-text {
    font-size: var(--text-typeset-font-size-xs);

    a {
      color: var(--color-ref-primary-600);
      text-decoration: none;

      &:visited,
      &:active,
      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}
