@import '../../../../theme/breakpoints';

.disclosure-notice {
  border-top: var(--border-width-ref-2) solid var(--color-sem-border-neutral-subtle);
  color: var(--color-sem-text-affiliate-default);
  font-size: var(--text-typeset-font-size-xs);
  font-style: normal;
  font-weight: var(--text-typeset-font-weights-normal-400);
  line-height: normal;
  padding: var(--space-sem-padding-xsmall) var(--space-sem-padding-small) 0;

  @media #{$medium} {
    padding: var(--space-sem-padding-xsmall) var(--space-sem-padding-medium) 0;
  }

  a {
    color: var(--color-sem-link-primary-default);
    text-decoration: none;
  }
}
