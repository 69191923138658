@import '../../../../theme/breakpoints';

.readmore-prefix {
  color: var(--color-sem-text-neutral-default);
  font-size: var(--text-typeset-font-size-m);
  font-weight: var(--text-typeset-font-weights-normal-700);
  line-height: var(--text-typeset-line-heights-m);
  padding-bottom: var(--space-sem-padding-small);
  text-decoration: none;
  text-transform: none;
}

.readmore-link {
  color: var(--color-sem-link-primary-default);
  font-size: var(--text-typeset-font-size-s);
  font-weight: var(--text-typeset-font-weights-normal-600);
  line-height: var(--text-typeset-line-heights-s);
  text-decoration: none;
  text-transform: none;
}
