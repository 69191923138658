@import '../../../../theme/breakpoints';

.additional-content {
  a {
    color: var(--color-sem-link-neutral-default);
    font-size: var(--text-typeset-font-size-s);
    font-weight: var(--typography-body-md-600-font-weight);
    line-height: var(--typography-body-md-600-line-height);
    text-decoration: none;
    text-transform: uppercase;
    @media #{$large} {
      line-height: var(--typography-body-lg-600-line-height);
    }
  }
}
