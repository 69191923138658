.tags {
  align-items: center;
  background-color: var(--color-sem-surface-neutral-default);
  border: var(--border-width-ref-1) solid var(--color-sem-surface-primary-default);
  border-radius: var(--border-radius-ref-6);
  color: var(--color-sem-text-primary-default);
  display: flex;
  font-size: var(--text-typeset-font-size-m);
  font-weight: var(--text-typeset-font-weights-normal-600);
  gap: var(--space-ref-2);
  height: var(--size-ref-11);
  justify-content: center;
  line-height: var(--text-typeset-line-heights-auto);
  opacity: var(--opacity-ref-100);
  padding: 0 var(--space-sem-padding-small);
  text-decoration: var(--text-typeset-text-decoration-none);

  :hover {
    opacity: var(--opacity-ref-80);
  }

  :disabled {
    opacity: var(--opacity-ref-50);
  }

  :focus-visible {
    outline: var(--border-width-ref-3) solid var(--color-ref-others-focus);
    outline-offset: var(--space-ref-2);
  }
}
