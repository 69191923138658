.numbered-list {
  color: var(--color-sem-text-neutral-default);
  counter-reset: item;
  display: flex;
  flex-direction: column;
  gap: 7px;
  list-style: none;
  margin: 0;
  padding: 0;
  text-decoration: var(--text-typeset-text-decoration-none);

  a,
  li {
    font-size: var(--text-typeset-font-size-m);
    font-weight: var(--typography-caption-md-400-font-weight);
    letter-spacing: var(--text-typeset-letter-spacing-normal);
    line-height: var(--text-typeset-line-heights-l);
    text-indent: 0;
  }

  li {
    padding-left: var(--space-ref-10);

    a {
      color: var(--color-sem-text-primary-default);

      :hover {
        text-decoration: var(--text-typeset-text-decoration-underline);
      }
    }

    &::before {
      color: var(--color-sem-text-neutral-subdue);
      content: counter(item);
      counter-increment: item;
      display: inline-block;
      margin-left: calc((var(--space-ref-10) * -1) + 1px);
      position: absolute;
    }
  }
}
