@import '../../../../theme/breakpoints';

.teaser-wob {
  background: var(--color-ref-neutral-1000);
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  position: relative;

  img,
  picture {
    display: block;
    width: 100%;
  }

  label {
    bottom: var(--space-ref-13);
    left: 50%;
    position: absolute;
    transform: translate(-50%, 50%);

    @media #{$medium} {
      left: var(--space-ref-9);
      transform: none;
    }

    @media #{$large} {
      bottom: var(--space-ref-16);
    }

    @media #{$xlarge} {
      bottom: var(--space-ref-13);
      left: var(--space-ref-12);
    }
  }

  h2 {
    font-size: var(--text-typeset-font-size-sem-teaser-l);
  }
}

.image-box {
  margin-bottom: -60px;
  position: relative;

  @media #{$medium} {
    margin-bottom: -70px;
  }

  @media #{$large} {
    margin-bottom: -100px;
  }
}

.content {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: var(--space-ref-3);
  justify-content: flex-end;
  padding: var(--space-ref-7) var(--space-ref-5);
  z-index: 1;

  @media #{$medium} {
    align-items: flex-start;
    padding: var(--space-ref-7) var(--space-ref-9);

    a [class*='headin'] {
      text-align: left;
    }
  }

  @media #{$xlarge} {
    align-items: flex-start;
    padding: var(--space-ref-12);
  }

  footer a {
    align-items: center;
    color: var(--color-ref-neutral-200);
    display: flex;
    font-size: var(--text-typeset-font-size-s);
    font-style: normal;
    font-weight: var(--text-typeset-font-weights-normal-600);
    gap: var(--space-sem-gap-2xsmall);
    justify-content: center;
    letter-spacing: var(--text-typeset-letter-spacing-normal);
    line-height: var(--text-typeset-line-heights-l);
    padding: 0 var(--space-sem-gap-3xsmall);
  }

  > div > a::before {
    left: 0;
  }
}

.overlay {
  background: linear-gradient(
    180deg,
    rgb(17 18 17 / 0%) 50%,
    rgb(17 18 17 / 60%) 70%,
    var(--color-ref-neutral-1000) 100%
  );
  height: 100%;
  position: absolute;
  width: 100%;
}
