@import '../../../../theme/breakpoints';

.factbox-container {
  background: var(--color-sem-surface-neutral-subtle);
  border-radius: var(--border-radius-sem-cta-med);
  margin: 0 auto;
  overflow: auto;
  padding: var(--space-ref-9);
  width: 100%;
}

.factbox-container img {
  width: 100%;
}

.factbox-container svg {
  margin-right: -4px;
  vertical-align: -3px;
}

.factbox-container h3 {
  margin: 0;
}

.factbox-container h1,
.factbox-container h2,
.factbox-container h4,
.factbox-container h5,
.factbox-container h6 {
  margin: 24px 0 8px;
}

@media #{$medium} {
  .factbox-container img {
    float: right;
    padding: 0 0 16px 16px;
    width: 50%;
  }
}

.content div {
  margin: 0 auto;
}

.content figure {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
}

.content figure img {
  padding: 0;
}

.content figcaption {
  background-color: transparent;
  display: none;
}

.heading-three {
  margin: 32px 0 8px;
  padding: 0 3px;
}

@media #{$medium} {
  .heading-three {
    padding: 0;
  }
}
