#modal {
  background: var(--color-sem-background-neutral-default);
  border-radius: var(--border-radius-ref-3);
  box-shadow: 0 0 0 1px #1415150d inset, 0 9px 86px 8px #1415151a, 0 24px 38px 3px #14151529;
  left: 50%;
  max-height: calc(100dvh - 100px);
  max-width: calc(100dvw - 100px);
  overflow: auto;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
  z-index: 9999;
}
