@import '../../../../theme/breakpoints';

.knowledge-recommender-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: var(--space-sem-gap-medium);

  @media #{$medium} {
    margin-bottom: var(--space-sem-gap-small);
  }

  @media #{$large} {
    margin-bottom: var(--space-sem-gap-medium);
  }

  @media #{$xlarge} {
    margin-bottom: var(--space-sem-gap-small);
  }

  h6 {
    color: var(--color-sem-text-neutral-default);
    font-size: var(--typography-heading-1-font-size);
    letter-spacing: var(--typography-heading-1-letter-spacing);
    line-height: var(--typography-heading-1-line-height);
    margin: 0;
    text-decoration: var(--text-typeset-text-case-upper);
    text-transform: var(--text-typeset-text-case-upper);
  }
}

.teaser-list {
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
}

@media #{$medium} {
  .teaser-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media #{$large} {
  .teaser-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

.teaser-list article {
  display: flex;
  flex-direction: column;
  justify-content: normal;
}

.teaser-list article img {
  aspect-ratio: unset;
}

.teaser-list article footer {
  display: none;
}
