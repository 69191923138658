@import '../../../../theme/breakpoints';

.web-alert-main-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.web-alert-container {
  align-items: center;
  background: var(--color-sem-surface-neutral-default);
  border-radius: var(--border-radius-ref-3);
  box-shadow: 0 24px 38px 3px rgb(20 21 21 / 16%), 0 9px 86px 8px rgb(20 21 21 / 10%),
    0 0 0 1px rgb(20 21 21 / 5%) inset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--space-sem-padding-medium);
  @media only screen and (min-width: 728px) {
    max-width: 100%;
    width: 728px;
  }

  // if media query is less than 728px, then apply the following styles
  @media only screen and (max-width: 728px) {
    max-width: 400px;
    width: 100%;
  }

  p {
    color: var(--color-sem-text-neutral-default);
    font-size: var(--text-typeset-font-size-m);
    font-weight: var(--typography-body-md-700-font-weight);
    gap: var(--space-sem-gap-small);
    letter-spacing: var(--text-typeset-letter-spacing-normal);
    line-height: var(--text-typeset-line-heights-m);
    text-align: center;
  }

  button:first-of-type {
    margin-bottom: var(--space-sem-gap-small);
  }

  button:last-of-type {
    gap: var(--space-com-cta-margin);
  }
}
