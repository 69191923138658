.placeholder-image {
  align-items: center;
  background: var(--color-sem-surface-neutral-subdue) no-repeat center;
  display: flex;
  justify-content: center;
}

.placeholder-aspect-ratio-2-3 {
  aspect-ratio: 2 / 3;
}

.placeholder-aspect-ratio-3-2 {
  aspect-ratio: 3 / 2;
}

.placeholder-aspect-ratio-16-9 {
  aspect-ratio: 16 / 9;
}

.placeholder-aspect-ratio-67-100 {
  aspect-ratio: 67 / 100;
}
