@import '../../../../theme/breakpoints';

.commenting-box-container {
  background: var(--color-sem-surface-primary-subtle);
  border-bottom: var(--border-width-sem-thin) solid;
  border-bottom-color: var(--color-sem-surface-primary-default);
  border-top: var(--border-width-sem-thin) solid;
  border-top-color: var(--color-sem-surface-primary-default);
  display: grid;
  grid-template-columns: 1fr;
  padding: var(--space-sem-padding-xsmall) var(--space-sem-padding-small);
}

.commenting-box-container-large-screen {
  background: var(--color-sem-surface-primary-subtle);
  border-bottom: 1px solid;
  border-bottom-color: var(--color-sem-surface-primary-default);
  border-top: 1px solid;
  border-top-color: var(--color-sem-surface-primary-default);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: var(--space-sem-padding-small) var(--space-sem-padding-medium);
}

.title-wrapper {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;

  h6 {
    color: var(--color-sem-text-neutral-default);
    font-size: var(--typography-heading-5-font-size);
    margin: 0;
    margin-left: 10px;
    padding: 0;
  }
}

.content {
  color: var(--color-sem-text-neutral-default);
  font-size: var(--text-typeset-font-size-s);
  font-style: normal;
  font-weight: var(--text-typeset-font-weights-normal-400);
  line-height: var(--text-typeset-line-heights-l);
  margin: 14px 0;

  @media #{$large} {
    font-size: var(--text-typeset-font-size-m);
  }
}

.text-content-svg-image-wrapper {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  gap: var(--size-ref-3);
  justify-content: space-between;
}

.svg-wrapper {
  svg {
    width: 100px;
  }
}

.left-wrapper {
  display: grid;
  grid-template-columns: 1fr;
}

.right-wrapper {
  svg {
    @media #{$medium} {
      width: 120px;
    }

    @media #{$xlarge} {
      width: 208px;
    }
  }
}

.app-links-wrapper {
  display: flex;
  flex-flow: row nowrap;
  gap: 0;
  justify-content: center;

  a {
    display: inline-block;
    margin: 0;
    padding: 0;

    svg {
      width: auto;
    }
  }
}
