@import '../../../../../theme/breakpoints';

.quiz-result {
  align-items: center;
  background-color: var(--color-sem-surface-neutral-subtle);
  color: var(--color-sem-text-neutral-default);
  display: flex;
  flex-direction: column;
  font-weight: var(--text-typeset-font-weights-normal-700);
  gap: var(--space-sem-gap-small);
  padding-bottom: 0;
  text-align: center;

  @media #{$medium} {
    gap: var(--space-sem-gap-medium);
  }
}

.result-message {
  display: block;
  font-family: var(--text-typeset-font-families-ref-1);
  font-size: var(--text-typeset-font-size-2xl);
  font-weight: var(--text-typeset-font-weights-normal-700);
  line-height: var(--text-typeset-line-heights-l);
  margin: 0;
}

.sub-heading {
  font-family: var(--text-typeset-font-families-ref-2);
  font-size: var(--text-typeset-font-size-l);
  font-weight: var(--text-typeset-font-weights-normal-700);
  line-height: var(--text-typeset-line-heights-l);
}

.score {
  font-family: var(--text-typeset-font-families-ref-1);
  font-size: var(--text-typeset-font-size-3xl);
  font-style: normal;
  font-weight: var(--text-typeset-font-weights-normal-700);
  line-height: var(--text-typeset-line-heights-xs);
}
