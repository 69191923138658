$wc-cta-width: 234px;
$whatsapp-colour: #3bb931;
$wc-desktop-bg: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA4MCIgaGVpZ2h0PSIyMDQ4IiB2aWV3Qm94PSIwIDAgMTA4MCAyMDQ4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHN0eWxlPSJkaXNwbGF5OiBub25lOyI+CjxtYXNrIGlkPSJtYXNrMF8xMTgwOV80MTM0MiIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMCIgeT0iMCIgd2lkdGg9IjEwODAiIGhlaWdodD0iMjA0OCI+CjxyZWN0IHdpZHRoPSIxMDgwIiBoZWlnaHQ9IjIwNDgiIGZpbGw9IiNEOUQ5RDkiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swXzExODA5XzQxMzQyKSI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTMwNy43MiA1MzAuNzcyQzEzNTMuODMgNjg3LjM1NSAxMjc3LjE4IDg3MC4xNCAxMTM3LjU5IDk3My4wNzNDOTk4LjAwMyAxMDc1LjM4IDc5Ni43MiAxMDk5LjA5IDY3NS4yMDMgMTAxMS4xM0M1NTQuMzEgOTIzLjE2NiA1MTMuODA0IDcyMy41MzggNTY4LjAxOSA1NTUuMTAyQzYyMi44NTggMzg2LjA0MiA3NzIuNDE3IDI0OC4xNzQgOTMyLjU3IDI0NS4wNTRDMTA5Mi4xIDI0MS45MzUgMTI2MS42IDM3My41NjUgMTMwNy43MiA1MzAuNzcyWk00ODQuNjM0IDYwMy4zMTRDNTM3Ljg5NiA3NjYuNDgyIDQ2My43OTIgOTcxLjMxIDMzNy41ODQgMTA1MC41OEMyMTAuNzk3IDExMjkuODUgMzEuOTA1MyAxMDgzLjU2IC04OS42NzE0IDk4OC4wOUMtMjExLjI0OCA4OTIuNjE5IC0yNzQuOTMxIDc0OC41NDUgLTIzOS4wMzcgNjA4LjUyMkMtMjAyLjU2NCA0NjkuMDc3IC02NS45MzUgMzM0LjI2MSA5Mi42OTM3IDMyNy4zMTdDMjUxLjMyMiAzMjAuMzc0IDQzMS4zNzIgNDQwLjcyNSA0ODQuNjM0IDYwMy4zMTRaTTg1OS4yMzkgMTk2MS45NUMxMDA3LjY5IDE4NDUuMDQgMTAzNi45MSAxNjAwLjE3IDk2Ny40MjEgMTM5NS41OEM4OTcuOTMyIDExOTEgNzMwLjUyNyAxMDI2LjY5IDU1NC40MzUgMTAyNC4zMkMzNzkuMTM0IDEwMjEuOTUgMTk0LjM1NyAxMTgwLjczIDEyOS42MDYgMTM3OC45OUM2NS42NDQzIDE1NzcuMjYgMTIwLjEzIDE4MTUuMDMgMjgyLjAwNyAxOTM5LjgzQzQ0My4wOTUgMjA2NS40MyA3MTAuNzg2IDIwNzguMDcgODU5LjIzOSAxOTYxLjk1WiIgZmlsbD0id2hpdGUiIGZpbGwtb3BhY2l0eT0iMC42Ii8+CjwvZz4KPC9zdmc+');

.wrapper {
  align-items: center;
  background-color: #{$whatsapp-colour}40;
  background-image: $wc-desktop-bg;
  background-position: center;
  background-size: cover;
  border-radius: 2px;
  margin: 0 auto;
  padding: 16px;
}

#whatsapp-community {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.logo-and-text {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.publication-logo {
  flex-shrink: 0;
  margin-right: 8px;
}

.leading-text {
  flex-grow: 1;
}

.leading-text h6 {
  color: #141515;
  font-size: 1.2rem;
  margin: 0;
  text-align: left;
}

.whatsapp-cta {
  align-items: center;
  display: flex;
  justify-content: center;
}

.paragraph-text p {
  color: #141515;
  font-size: 0.9rem;
  margin: 10px 0;
  text-align: left;

  a {
    color: #141515;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.whatsapp-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}
