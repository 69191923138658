@import '../../../../theme//breakpoints.scss';

.wrapper {
  align-items: center;
  align-self: stretch;
  background: var(--color-sem-surface-neutral-subdue);
  display: flex;
  flex-direction: column;
  gap: var(--space-sem-gap-xsmall);
  padding: var(--space-ref-9);

  @media #{$large} {
    padding: var(--space-ref-11) var(--space-ref-12);
  }
}

.message {
  color: var(--color-sem-text-neutral-default);
  font-size: var(--text-typeset-font-size-m);
  font-style: normal;
  font-weight: var(--text-typeset-font-weights-normal-700);
  letter-spacing: var(--text-typeset-letter-spacing-normal);
  line-height: var(--text-typeset-line-heights-m);
  margin: 0;
  text-align: center;
}
