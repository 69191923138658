@import '../../../../theme/breakpoints';

.box-container {
  margin-bottom: var(--space-sem-margin-medium);
  width: 100%;

  &.commercial {
    overflow: visible;
  }

  &.non-commercial {
    overflow: hidden;
  }

  @media #{$medium} {
    max-height: 480px;
  }
}
