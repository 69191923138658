@import '../../../../theme/breakpoints';

.wrapper {
  display: grid;
  padding: 9px 0;

  @media #{$small} {
    iframe {
      min-width: 288px !important;
    }
  }

  @media #{$medium} {
    margin: 0 auto;
    max-width: 600px;
    width: 100%;

    iframe {
      min-width: 326px !important;
    }
  }
}

.blockquote {
  width: 100%;
}
