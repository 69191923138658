@import '../../../../theme/breakpoints';

.comments-wrapper {
  padding-top: 10px;
  scroll-margin-top: 150px;
}

@media #{$large} {
  .comments-wrapper {
    scroll-margin-top: 170px;
  }
}
