@import '../../../../../theme/breakpoints';

.questionnaire {
  background-color: var(--color-sem-surface-neutral-subtle);
  display: flex;
  flex-direction: column;
  gap: var(--space-sem-gap-small, 16px);

  div {
    margin-bottom: 0;
  }

  h6 {
    margin: 0;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  @media #{$medium} {
    gap: var(--space-sem-gap-medium);
  }
}

.question {
  margin: 0;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.option-default {
  align-items: center;
  background-color: var(--color-sem-surface-neutral-default);
  border: none;
  border-radius: 8px;
  color: var(--color-sem-text-neutral-default);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 58px;
  opacity: 1;
  padding: var(--space-sem-padding-xsmall) var(--space-sem-padding-small);

  span {
    flex: 1;
    text-align: left;
  }

  img {
    text-align: right;
  }

  &:hover {
    box-shadow: 0 0 0 2px var(--color-sem-cta-sg-tertiary-hover);
  }
}

.option-is-selected {
  opacity: 0.5;

  &:hover {
    box-shadow: none;
  }
}

.option-selected {
  border: 2px solid var(--color-sem-cta-sg-tertiary-hover);
}

.option-correct {
  background-color: var(--color-sem-surface-success-pressed);
  opacity: 1;
}

.option-wrong {
  background-color: var(--color-sem-surface-error-pressed);
  opacity: 1;
}

.option-correct-index {
  background-color: var(--color-sem-surface-success-pressed);
  opacity: 1;
}

.correct-answer-message {
  align-items: flex-start;
  color: var(--color-sem-text-neutral-default);

  span {
    margin-left: var(--space-sem-gap-xsmall);
  }

  img {
    margin-top: var(--space-ref-8);
  }
}
