@import '../../../../theme/breakpoints';

.quiz-container {
  background-color: var(--color-sem-surface-neutral-subtle);
}

.top-heading {
  align-items: center;
  background-color: var(--color-sem-surface-neutral-subdue);
  display: flex;
  flex-direction: row;
  padding: var(--space-ref-4, 12px) var(--space-sem-padding-small, 16px);

  @media #{$medium} {
    padding: var(--space-sem-padding-small) var(--space-sem-padding-x-large);
  }
}

.content-section-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
  padding: var(--space-ref-6) var(--space-ref-5);

  @media #{$medium} {
    padding: var(--space-ref-10) var(--space-ref-15);
  }
}

.progress-item {
  display: flex;
  gap: var(--space-sem-gap-2xsmall);
  justify-content: flex-end;
  width: 100%;
}

.question-progress {
  span {
    font-weight: bold;
  }
}

.score-progress {
  border-left: 1px solid var(--color-sem-border-neutral-default);
  margin-left: 8px;
  padding-left: 8px;

  span {
    font-weight: bold;
  }
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--space-sem-gap-small);

  @media #{$medium} {
    margin-top: var(--space-sem-gap-medium);
  }
}

.button-wrapper-quiz-complete {
  justify-content: center;
  margin-top: var(--space-sem-gap-small);

  @media #{$medium} {
    margin-top: var(--space-sem-gap-medium);
  }
}
