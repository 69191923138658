@import '../../../../theme/breakpoints';

.pdf-embed-container {
  display: block;
}

.image-wrapper {
  background-color: var(--color-sem-surface-neutral-subdue);
  display: block;
  padding: var(--space-ref-5) var(--space-ref-5) var(--space-ref-0);
  @media #{$medium} {
    padding: var(--space-ref-13) var(--space-ref-13) var(--space-ref-0);
  }

  img {
    display: block;
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }
}

.heading {
  color: var(--color-sem-text-on-primary-default);
  font-size: var(--text-typeset-font-size-l);
  font-weight: var(--typography-body-lg-700-font-weight);
  letter-spacing: var(--typography-body-lg-700-letter-spacing);
  line-height: var(--typography-body-lg-700-line-height);
}

.content-wrapper {
  align-self: stretch;
  background-color: var(--color-sem-surface-primary-default);
  display: flex;
  flex-direction: column;
  gap: var(--space-sem-gap-medium);
  justify-content: flex-end;
  padding: var(--space-ref-5);
  @media #{$medium} {
    padding: var(--space-ref-9) var(--space-sem-padding-large);
  }

  a {
    color: var(--color-sem-cta-fg-neutral-default);
    font-family: inherit;
    font-size: var(--text-typeset-font-size-m);
    font-style: normal;
    font-weight: var(--typography-sem-cta-label-font-weight);
    line-height: normal;
    margin: 0 0 0 auto;
    margin-left: auto;
    text-decoration: none;
    padding: {
      bottom: 0;
      top: 0;
    }
  }
}

.btn-tracking {
  display: inline-flex;
}
