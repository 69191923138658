@import '../../../../theme/breakpoints';

.lead-text {
  color: var(--color-sem-text-neutral-default);
  font-size: var(--text-typeset-font-size-m);
  font-weight: var(--typography-body-md-600-font-weight);
  letter-spacing: var(--text-typeset-letter-spacing-normal);
  line-height: var(--text-typeset-line-heights-l);

  @media #{$small} {
    font-size: var(--typography-body-md-600-font-size);
    line-height: var(--typography-body-md-600-line-height);
  }

  @media #{$medium} {
    font-size: var(--typography-body-md-600-font-size);
    line-height: var(--typography-body-md-600-line-height);
  }

  @media #{$large} {
    font-size: var(--typography-body-sm-600-font-size);
    line-height: var(--typography-body-lg-400-line-height);
  }

  @media #{$xlarge} {
    font-size: var(--typography-body-sm-600-font-size);
    line-height: var(--typography-body-lg-400-line-height);
  }
}
