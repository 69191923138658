.unordered-list {
  color: var(--color-sem-text-neutral-default);
  display: flex;
  flex-direction: column;
  font-size: var(--text-typeset-font-size-m);
  font-weight: var(--typography-caption-md-400-font-weight);
  gap: 7px;
  letter-spacing: var(--text-typeset-letter-spacing-normal);
  line-height: var(--text-typeset-line-heights-l);
  margin: 0;
  padding-inline-start: var(--space-ref-6);

  & > li {
    padding-left: var(--space-ref-3);

    &::marker {
      color: var(--color-sem-text-neutral-subdue);
    }
  }

  a {
    color: var(--color-sem-text-neutral-default);
    text-decoration: var(--text-typeset-text-decoration-none);

    :hover {
      text-decoration: var(--text-typeset-text-decoration-underline);
    }
  }
}
