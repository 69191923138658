.square-logo {
  display: flex;
}

.xsmall {
  height: 20px;
  width: 20px;
}

.small {
  height: 24px;
  width: 24px;
}

.mid {
  height: 48px;
  width: 48px;
}
