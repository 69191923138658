.authors-container {
  color: var(--color-sem-text-neutral-subdue);

  /* typography/body/md/400 */
  font-size: var(--text-typeset-font-size-m);
  font-style: normal;
  font-weight: var(--text-typeset-font-weights-normal-400);
  line-height: var(--text-typeset-line-heights-l);

  /* 25.6px */

  &::before {
    content: 'By';
    margin-right: var(--size-ref-2);
  }
}
