@import '../../../../theme/breakpoints';

.video-wrapper {
  background: var(--color-sem-surface-neutral-subtle, #404040);
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-top: 12px;
  padding-top: 56.25%; // 16:9 height prevents subtle layout shift
  position: relative;
  width: 100%;
  @media #{$large} {
    margin-bottom: 18px;
  }
}

.aspect-ratio-wrapper {
  aspect-ratio: 16/9;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.mounting-element {
  background-color: transparent;
  height: 100%;
  min-height: 200px;
  min-width: 200px;
  position: relative;
  width: 100%;
}

.image-placeholder {
  height: 100%;
  max-width: 100%;
  width: 100%;
}

.video-caption {
  align-items: center;
  background: var(--color-ref-neutral-900);
  color: var(--color-ref-neutral-0);
  display: flex;
  font-size: var(--typography-heading-6-font-size);
  font-weight: var(--text-typeset-font-weights-normal-400);
  gap: 10px;
  justify-content: center;
  letter-spacing: var(--text-typeset-letter-spacing-normal);
  line-height: var(--text-typeset-line-heights-2xs);
  padding: var(--space-ref-4) 0;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  @media #{$medium} {
    align-items: center;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    min-height: 36px;
  }
}

.play-button-div {
  > button {
    height: 66px;
    width: 66px;

    > svg {
      height: 36px;
      width: 36px;
    }
  }
}
