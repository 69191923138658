@import '../../../../theme/breakpoints';

.heading-primary {
  .heading {
    color: var(--color-sem-text-neutral-bold);
    font-style: normal;
    font-weight: var(--text-typeset-font-weights-normal-700);
    letter-spacing: var(--text-typeset-letter-spacing-normal);
    margin: 0;
    position: relative;
  }

  .affiliated-label {
    color: var(--color-ref-affiliate-700);
    font-family: var(--text-typeset-font-families-ref-1);
    font-size: var(--text-typeset-font-size-3xl);
    font-style: normal;
    font-weight: var(--text-typeset-font-weights-normal-700);
    letter-spacing: var(--text-typeset-letter-spacing-3x-dense);
    line-height: var(--text-typeset-line-heights-xs);
    padding-right: var(--space-ref-2);
    @media #{$medium} {
      font-size: var(--text-typeset-font-size-5xl);
    }
    @media #{$large} {
      font-size: var(--text-typeset-font-size-7xl);
    }
  }

  .advertisement-label {
    font-size: var(--text-typeset-font-size-3xl);
    font-weight: var(--text-typeset-font-weights-normal-300);
    line-height: var(--text-typeset-line-heights-l);
    padding-right: var(--space-ref-4);
    @media #{$medium} {
      font-size: var(--text-typeset-font-size-5xl);
    }
    @media #{$large} {
      font-size: var(--text-typeset-font-size-7xl);
    }
  }
}

.heading-secondary {
  .heading {
    color: var(--color-sem-text-neutral-bold);
    font-size: var(--text-typeset-font-size-xs);
    font-style: normal;
    font-weight: var(--text-typeset-font-weights-normal-700);
    line-height: normal;
  }

  @media #{$large} {
    gap: var(--space-ref-5);
  }
}

.heading-opinion {
  .heading {
    color: var(--color-sem-text-neutral-subdue);
    font-size: var(--text-typeset-font-size-l);
    font-style: italic;
    font-weight: var(--text-typeset-font-weights-normal-400);
    letter-spacing: var(--text-typeset-letter-spacing-5x-dense);
    line-height: var(--text-typeset-line-heights-xs);
    margin: 0;
    position: relative;
  }
}

.heading-wob {
  @media #{$small} {
    text-align: center;
  }
  @media #{$medium} {
    text-align: left;
  }

  .heading {
    color: var(--color-ref-neutral-0);
    display: block;
    font-style: normal;
    font-weight: var(--text-typeset-font-weights-normal-700);
    text-align: center;
  }

  .affiliated-label {
    color: var(--color-ref-affiliate-500);
    font-family: var(--text-typeset-font-families-ref-1);
    font-size: var(--text-typeset-font-size-3xl);
    font-style: normal;
    font-weight: var(--text-typeset-font-weights-normal-700);
    letter-spacing: var(--text-typeset-letter-spacing-3x-dense);
    line-height: var(--text-typeset-line-heights-xs);
    padding-right: var(--space-ref-2);
    @media #{$medium} {
      font-size: var(--text-typeset-font-size-5xl);
      text-align: left;
    }
    @media #{$large} {
      font-size: var(--text-typeset-font-size-7xl);
    }
  }
}

.teaser-link,
.teaser-link-opinion,
.teaser-link-primary,
.teaser-link-secondary,
.teaser-link-wob {
  align-items: center;
  justify-content: center;
  text-decoration: none;
  z-index: 1;

  &::before {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  @media #{$medium} {
    &::before {
      bottom: 6%;
    }
  }

  @media #{$large} {
    &::before {
      bottom: 10%;
    }
  }

  .heading {
    margin: 0;
    text-transform: none;
    word-break: break-word;
  }

  &:hover,
  &:active,
  &:visited {
    .heading {
      text-decoration: underline;
      text-decoration-thickness: 0.1em;
      text-underline-offset: 5px;
    }
  }

  &:hover .heading {
    text-decoration-color: var(--color-sem-text-primary-default);
  }

  &:active .heading {
    text-decoration-color: var(--color-sem-text-primary-default);
  }

  &:visited .heading {
    text-decoration-color: var(--color-sem-text-primary-default);
  }

  &:focus-visible {
    outline: var(--border-width-sem-thinner) solid var(--color-sem-focus-default);
    outline-offset: var(--space-sem-padding-2xsmall);
  }

  :first-child {
    display: inline;
  }
}
