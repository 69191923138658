@import '../../../../theme/breakpoints';

.container {
  overflow: hidden;
  position: relative;

  .instagram {
    align-items: normal;
    aspect-ratio: auto 0.8;
    display: flex;
    height: auto;
    justify-content: center;
    justify-items: normal;
    position: relative;

    @media #{$medium} {
      aspect-ratio: auto 1.05;
    }
    @media #{$large} {
      aspect-ratio: auto 1.4;
    }

    > iframe {
      width: 100%;
    }

    > div {
      text-align: center;
    }
  }

  .tiktok {
    align-items: center;
    display: grid;
    height: 793px;
    justify-content: center;
    position: relative;
  }

  .twitter {
    align-items: center;
    aspect-ratio: auto 1.05;
    display: flex;
    justify-content: center;
    position: relative;

    @media #{$medium} {
      aspect-ratio: auto 1.5;
    }
    @media #{$large} {
      aspect-ratio: auto 2.1;
    }

    &.right-handrail-enabled {
      @media #{$large} {
        aspect-ratio: auto 1.5;
      }
    }

    > iframe {
      width: 100%;
    }

    > div {
      text-align: center;
    }
  }

  .iya1 {
    align-items: center;
    aspect-ratio: auto 1.2;
    display: grid;
    justify-content: normal;
    position: relative;
    @media #{$medium} {
      aspect-ratio: auto 1.6;
    }
    @media #{$large} {
      aspect-ratio: auto 2.1;
    }

    &.right-handrail-enabled {
      @media #{$large} {
        aspect-ratio: auto 1.4;
      }
    }

    > p {
      margin: 16px auto;
    }
  }

  .iya2 {
    align-items: center;
    aspect-ratio: auto 1.3;
    display: flex;
    justify-content: center;
    justify-items: center;
    position: relative;
    @media #{$medium} {
      aspect-ratio: auto 2;
    }
    @media #{$large} {
      aspect-ratio: auto 2.4;
    }

    &.right-handrail-enabled {
      @media #{$large} {
        aspect-ratio: auto 1.4;
      }
    }
  }

  .cheatah {
    align-items: center;
    aspect-ratio: auto 0.75;
    display: flex;
    justify-content: center;
    position: relative;
    @media #{$medium} {
      aspect-ratio: auto 1.4;
    }
    @media #{$large} {
      aspect-ratio: auto 1.9;
    }

    &.right-handrail-enabled {
      @media #{$large} {
        aspect-ratio: auto 1.3;
      }
    }

    > iframe {
      width: 100%;
    }

    > div {
      text-align: center;
    }
  }

  .data-unit {
    align-items: center;
    aspect-ratio: auto 0.8;
    display: flex;
    justify-content: center;
    position: relative;
    @media #{$medium} {
      aspect-ratio: auto 1.2;
    }
    @media #{$large} {
      aspect-ratio: auto 1.4;
    }

    &.right-handrail-enabled {
      @media #{$large} {
        aspect-ratio: auto 1.2;
      }
    }
  }

  .static {
    align-items: center;
    display: grid;
    justify-content: left;
    position: relative;

    > iframe {
      width: 100%;
    }

    > div {
      text-align: center;
    }
  }
}
