@import '../../../../theme/breakpoints';

.sharebar-wrapper {
  align-items: center;
  border-bottom: var(--border-width-ref-2) solid var(--color-sem-border-neutral-subtle);
  display: flex;
  flex-direction: row;
  gap: var(--space-ref-2);
  justify-content: space-between;
  padding-bottom: var(--space-ref-5);
  width: 100%;

  .sharebar-inner-wrapper {
    align-items: center;
    display: flex;
    flex-grow: 1;
    gap: var(--space-ref-2);
    justify-content: flex-start;
  }

  ul {
    display: flex;
    gap: var(--space-ref-2);
    list-style: none;
    margin: 0;
    padding: 0;

    li.whatsapp {
      @media #{$medium} {
        display: none;
      }
    }
  }
}
