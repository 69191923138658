.content-rating {
  .star-icon {
    color: var(--color-ref-neutral-300);

    &.active {
      color: var(--color-ref-primary-600);
    }

    svg {
      height: var(--size-sem-icon-medium);
      margin-right: 5px;
      width: var(--size-sem-icon-medium);
    }
  }
}
