@import '../../../../theme/breakpoints';

.figure {
  align-content: center;
  background: var(--color-sem-text-on-primary-default);
  display: grid;
  height: 100%;
  justify-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  padding: var(--space-ref-9);
  width: 100%;
  z-index: 10;

  @media #{$medium} {
    padding: var(--space-ref-11) var(--space-ref-9);
  }
  @media #{$large} {
    padding: var(--space-ref-11) var(--space-ref-12);
  }

  figcaption {
    color: var(--color-sem-text-neutral-default);
    font-size: var(--text-typeset-font-size-m);
    font-weight: var(--typography-body-md-700-font-weight);
    line-height: var(--typography-body-md-700-line-height);
  }
}

.placeholder {
  > img {
    filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(303deg) brightness(106%) contrast(101%);
  }
}

.hidden {
  visibility: hidden;
}
