@import '../../../../theme/breakpoints';

.content-tags {
  align-items: center;
  background: var(--color-sem-surface-neutral-default);
  border: 1px solid var(--color-sem-border-neutral-subtle);
  display: flex;
  flex-flow: row wrap;
  gap: var(--space-ref-3);
  padding: var(--space-ref-5);

  @media #{$large} {
    padding: var(--space-ref-7);
  }
}
