@import '../../../../theme/breakpoints';

.top-slot-container {
  background-color: var(--color-ref-primary-300);
  margin-left: -24px !important;
  margin-right: -24px !important;
  max-width: var(--wrapper-l);
  padding: var(--space-sem-margin-medium);

  @media #{$xlarge} {
    margin: 0 auto;
    max-width: var(--wrapper-xl);
  }
}

.recommended-wrapper {
  display: grid;
  gap: var(--space-ref-5);

  .skeletton-item {
    display: grid;
    gap: var(--space-ref-3);
  }
}

.cover-wrapper {
  display: grid;
  position: relative;

  img {
    height: 100%;
    margin: 0;
    max-width: 100%;
    width: 100%;
  }

  label {
    bottom: var(--space-ref-3);
    left: var(--space-ref-3);
    position: absolute;
  }
}

.teaser {
  display: flex;
  flex-direction: column;
  gap: var(--space-ref-3);
  padding: 0;
  position: relative;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-decoration-color: var(--color-sem-text-neutral-bold);
  }

  .heading {
    -webkit-box-orient: vertical;
    color: var(--color-sem-text-neutral-bold);
    display: -webkit-box;
    font-style: normal;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    overflow: hidden;
  }

  .advertisement-label {
    background-color: var(--color-ref-neutral-600);
    color: white;
    font-size: var(--size-ref-3);
    font-weight: var(--text-typeset-font-weights-normal-400);
    left: 0;
    line-height: var(--text-typeset-line-heights-l);
    padding: 2px 6px;
    padding-right: var(--space-ref-4);
    position: absolute;
    top: 6px;
  }
}

.teaser-primary {
  .heading {
    font-size: var(--text-typeset-font-size-s);
    font-weight: var(--text-typeset-font-weights-normal-600);
    margin: 0;
    position: relative;
  }
}

.teaser-secondary {
  display: grid;
  flex-direction: row-reverse;
  grid-template-columns: 1fr 1fr;
  justify-content: center;

  > :first-child {
    order: 0;
  }

  .heading {
    font-size: var(--text-typeset-font-size-xs);
    font-weight: var(--text-typeset-font-weights-normal-700);
    margin-top: 0;
  }

  @media #{$large} {
    gap: var(--space-ref-5);
  }
}

.teaser-link {
  align-items: center;
  justify-content: center;
  text-decoration: none;
  z-index: 1;

  :first-child {
    display: inline;
  }

  @media #{$medium} {
    &::before {
      bottom: 6%;
    }
  }

  @media #{$large} {
    &::before {
      bottom: 10%;
    }
  }

  &:focus-visible {
    outline: var(--border-width-sem-thinner) solid var(--color-sem-focus-default);
    outline-offset: var(--space-sem-padding-2xsmall);
  }
}

.additional-content {
  a {
    align-items: center;
    color: var(--color-sem-link-neutral-default);
    display: flex;
    font-size: var(--text-typeset-font-size-2xs);
    font-weight: var(--typography-body-md-600-font-weight);
    line-height: var(--typography-body-md-600-line-height);
    text-decoration: none;
    text-transform: lowercase;
    @media #{$large} {
      line-height: var(--typography-body-lg-600-line-height);
    }
  }
}
