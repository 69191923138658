.input-wrapper {
  position: relative;

  &.full-width {
    flex-grow: 1;
  }
}

.input-field {
  border: 1px solid #d4d5d5;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  padding: 10px;
  width: 100%;

  &.invalid {
    border: 1px solid red;
  }
}

.errmsg {
  color: red;
  font-size: 0.75em;
  margin-top: 5px;
}
