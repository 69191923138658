@import '../../../../theme/breakpoints';

.article {
  display: grid;
  height: auto;
  margin: 0 0 5px;
  max-width: var(--wrapper-xs);
  overflow-wrap: anywhere;
  padding: 0 var(--layout-margin-m);

  h1 {
    color: var(--color-sem-text-neutral-default);
  }

  @media #{$small} {
    max-width: 100%;
    width: 100%;
  }

  @media #{$medium} {
    max-width: var(--wrapper-l);
  }

  @media #{$large} {
    padding: 0 var(--layout-margin-m);
  }
}
