@import '../../../../theme/breakpoints';

.grid-container {
  background: var(--color-sem-surface-primary-subtle);
  display: grid;
  gap: var(--space-ref-5);
  padding: var(--space-ref-5);
}

.grid-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: var(--space-sem-gap-xsmall);

  h6 {
    color: var(--color-sem-text-neutral-default);
    margin: 0;
    text-transform: uppercase;
  }
}

.grid-wrapper {
  color: var(--color-sem-text-neutral-bold);
  display: grid;
  gap: var(--space-ref-5);
  grid-template-columns: 1fr;

  @media #{$medium} {
    grid-template-columns: repeat(var(--tiles-number), 1fr);
  }
}
